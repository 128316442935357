import * as React from 'react'
import Layout from '../components/Layout'
import ProjectList from '../components/ProjectList'
import Seo from '../components/SEO'

const IndexPage = () => {
  return (
    <Layout>
      <Seo />
      <header className="relative flex flex-col items-center justify-center">
        <h1 className="mt-6 mb-2 text-5xl md:text-8xl text-center">Juan Duran</h1>
        <h2 className="mb-12 md:mb-32 text-3xl md:text-5xl text-center">Full Stack Developer</h2>
      </header>
      <main className="relative flex flex-col items-center justify-center">
        <h3 className="my-2 text-3xl uppercase">Projects</h3>
        <ProjectList />
      </main>
    </Layout>
  )
}

export default IndexPage
